<template>
  <div class="row justify-content-between pt-2 pb-2 ml-3 mr-3 position-relative">
    <div class>
      <img src="../../assets/img/search/3.0sxtk_search_icon_search.png" alt />
    </div>

    <div class="text-center mt-3 mb_qiye">
      <router-link to="/database/data-manager">
        <a
          class="d-inline-block btn-addCompany1 br_4 fz-14 cursor_pionter"
          style="cursor: pointer"
        >
          <i class="fz-14 mr-1">+</i> 新增我的企业
        </a>
      </router-link>
    </div>
    <div class="col">
      <input
        type="text"
        class="col"
        placeholder="请输入公司名称关键词"
        @keydown.down="keyDownChoose"
        @keydown.up="keyUpChoose"
        v-model="searchText"
        @keyup="QC"
      />
    </div>
    <div class="cursor_pionter color-4d" style="line-height: 30px" @click="toCompanyList">
      搜索
    </div>

    <div class="position-absolute search-list" v-show="searchText != ''">
      <a
        href="javascript:void(0)"
        v-for="(item, index) in searchLists"
        :key="index"
        class="d-block mt-2 search-items"
        @mouseover="mouseClearStyle"
        @click="stepToCompanyInfo(item)"
      >
        <span class="color-4d search-hot-title d-inline-block fz-14 text-center"
          >公司</span
        >
        <span class="color-4d ml-4 fz-14 text-center">{{ item.name }}</span>
        <span class="color-4d ml-10 fz-14 text-center">({{ item.create_time }})</span>
      </a>
    </div>
  </div>
</template>

<script>
import * as sesstion from "../../store/localStorage";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      searchLists: [],
      searchText: "",
      timer: null,
    };
  },
  methods: {
    ...mapActions([
      "queryPutData",
      "keyDownChoose",
      "keyUpChoose",
      "saveClickOrHoverIndex",
      "mouseClearStyle",
    ]),
    toCompanyList() {
      if (this.searchText != "") {
        sesstion.setLocalData("searchText", this.searchText);
        this.$router.push({ name: "CauseResult" });
      } else {
        return false;
      }
    },
    stepToCompanyInfo(item) {
      item.isCustom = true;
      sesstion.setLocalData("company", item);
      this.$router.push({ name: "causeCompany" });
    },
    QC() {
      !!this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(this.queryCompany, 1000);
    },
    queryCompany() {
      var value = this.searchText;
      if (value != "") {
        this.$http
          .get(window.BASE_URL + "/company/my/search/" + value + "/" + 3 + "/", {
            headers: {
              Authorization: "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
            },
          })
          .then(
            (res) => {
              this.searchLists = res.data;
            },
            (res) => {
              // console.log("请求失败！");
            }
          );
      }
    },
  },
  computed: {
    ...mapGetters({
      keyDownNows: "keyDownNow",
    }),
  },
};
</script>

<style scope>
.btn-addCompany1 {
  width: 140px;
  height: 36px;
  line-height: 36px;
  color: #ffffff !important;
  border: 1px solid #ffffff;
}
.mb_qiye {
  position: absolute;
  right: 0;
  top: -56px;
}
@media (max-width: 460px) {
  .mb_qiye {
    position: absolute;
    right: 0;
    top: -51px;
  }
}
</style>
